export const schema = {
  activity: Array.from({length: 12}, () => ({total: 0})),
  changelogFilename: ``,
  created: 1489597229367,
  dependencies: {},
  devDependencies: {},
  dependents: 0,
  deprecated: false,
  description: `…`,
  downloadsLast30Days: 0,
  downloadsRatio: 0,
  jsDelivrHits: 0,
  github: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    stargazers_count: 0,
  },
  gitlab: {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    star_count: 0,
  },
  bundlesize: undefined,
  homepage: ``,
  humanDependents: `0`,
  humanDownloadsLast30Days: `0`,
  keywords: [],
  lastCrawl: ``,
  lastPublisher: {
    name: ``,
    email: ``,
    avatar: `https://avatars3.githubusercontent.com/u/22247014?v=3&s=200`,
    link: ``,
  },
  license: ``,
  modified: 1489597229367,
  name: `…`,
  objectID: ``,
  owner: {
    name: ``,
    avatar: `https://avatars3.githubusercontent.com/u/22247014?v=3&s=200`,
    link: ``,
  },
  owners: [
    {
      name: ``,
      email: ``,
      avatar: `https://avatars3.githubusercontent.com/u/22247014?v=3&s=200`,
      link: ``,
    },
  ],
  popular: false,
  popularName: ``,
  readme: ``,
  version: ``,
  versions: {},
  tags: {},
  types: {},
  loaded: false,
};
