export const algolia = {
  appId: `OFCNCOG2CU`,
  apiKey: `f54e21fa3a2a0160595bb058179bfb1e`,
  indexName: `npm-search`,
};

export const docsearch = {
  apiKey: `3949f721e5d8ca1de8928152ff745b28`,
  indexName: `yarnpkg`,
};
